@import "../../variables.css";

.button{
    cursor: pointer;
    text-decoration: none;
    color: black;
    padding:16px;
    border-radius:5px;
    background:none;
    border:none;
    font-family: var(--font-stack);
    *{
        box-sizing: border-box;
    }
}

.content{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border:dashed 2px #E1E7E9;
    height: 300px;
    transition: 150ms ease-in-out;
    padding:24px;
}
.icon{
    border: solid 2px var(--gray-darkest);
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    align-items: center;
    transition: 150ms ease-in-out;
    position: relative;
}
.title{
    margin:0;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

// States
// Hover
.button:hover{
    .icon{
        transition: 150ms ease-in-out;
    }
}

// Focus
.button:focus{
    box-shadow: inset 0px 0px 0px 1px var(--gray);
    outline:none;
    .content{
        border:2px dashed transparent;
    }
     .icon{
        transition: 150ms ease-in-out;
    }
}
