body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f5f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.requirement {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.crisp-fieldset{
  border:0;
  padding: 0;
}
.crisp-legend, .crisp-label{
  color:var(--gray-darkest);
  font-weight:600;
  margin-bottom:16px;
}

.dropzoneLaunchImage {
  background-color: #F3F5F7 !important;
  padding: 103px 24px !important;
}

.dropzoneAppIcon {
  background-color: #F3F5F7 !important;
  padding: 29px 24px !important;
}

.hoverGreen:hover {
  background-color: #00A65A !important;
}