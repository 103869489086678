.colorSelectors{
    display: flex;
    gap:1rem;
    width:600px;
    margin-bottom:3rem;
    > *{
        flex-grow: 1;
        flex-basis:50%;
    }
}
.autoOrUpload{
    display: flex;
    width:600px;
    gap:1rem;
    > *{
        flex-grow: 1;
        flex-basis:50%;
    }
}
.formGroup{
    margin-top:1rem;
}
.FormGroupLabel{
margin-bottom:1rem;
}

.iconRow{
    display: flex;
    width:600px;
    gap:1rem;
    margin-top:3rem;
    > *{
        flex-grow: 1;
        flex-basis:50%;
    }
}