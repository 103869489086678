/* Define design tokens/CSS Variables globally via root class. Inherited in tailwind.config.js and in symantic CSS classes */
:root {
    /* Colors */
    --primary: #2b811d;
    --primary-blue: #1915e8;
    --gray-darkest: #000000;
    --gray-darker: #142c34;
    --gray-dark: #7e898d;
    --gray: #c7d1d4;
    --gray-light: #e1e7e9;
    --gray-lighter: #f3f5f7;
    --gray-lightest: #ffffff;

    /* Typography */
    --font-stack: 'Source Sans Pro',sans-serif;
  }