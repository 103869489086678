@import "../../variables.css";

.card{
    cursor: pointer;
    display: flex;
    font-family: var(--font-stack);
    position: relative;
    *{
        box-sizing: border-box;
    }
}

.constrained{
    max-width: 250px;
}

.container{
    padding:16px;
    border-radius:5px;
    box-sizing: border-box;
    display: flex;
    width:100%;
}
.input{
    position:absolute;
    left:-99999px;
}
.message{
    position: absolute;
    background-color: var(--gray-darkest);
    color:white;
    padding: 8px 16px;
    z-index: 999;
    opacity: 0;
    width:250px;
    border-radius: 3px;
    font-size: 13px;
    transition: opacity 200ms;
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.15);
    p{
        margin:0;
    }
}
.message.messageActive{
    opacity: 1;
}
.toggle{
    position:absolute;
    right: 8px;
    top: 8px;
    height:24px;
    width:24px;
    border-radius:100%;
    display:flex;
    justify-content:center;
    align-content:center;
    opacity: 0;
    transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
    transform: scale(0);
    z-index: 9;
    &:after{
        content:url("data:image/svg+xml,%3Csvg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.401 1.0554L4.31795 10.9347L0.333496 6.95029L1.51201 5.77178L4.19422 8.45399L11.1111 0L12.401 1.0554Z' fill='white'/%3E%3C/svg%3E%0A");
        display:block;
        line-height: 24px;
        opacity: 0;
        
    }
    &:before{
        content: '';
        width:calc(100% + 6px);
        height:calc(100% + 6px);
        top:-5px;
        left:-5px;
        border-radius: 100%;
        position: absolute;
        border:solid 2px var(--gray-darkest);
        opacity: 0;
        transform: scale(0);
        transition: transform 100ms, opacity 100ms;
    }
}

.content{
    position:relative;
    box-shadow: 0px 0px 5px 2px rgb(126 137 141 / 15%);
    border-radius:5px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    transition: 100ms ease-in-out;
    width: 100%;
}

.img{
    width:100%;
    aspect-ratio: 1 / 1;
    background:#f6f6f6;
    object-fit: cover;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.title{
    padding:12px;
    margin:0;
    font-size: 15px;
    font-weight: normal;
    flex-grow: 1;
    align-items: center;
    display: flex;
    color:var(--gray-darkest);
    background-color: var(--gray-lightest);
}

// States:
// Checked
.input:checked ~ .container{
    box-shadow: inset 0px 0px 0px 2px var(--gray-darkest);
    .toggle{
        opacity: 1;
        background-color:var(--gray-darkest);
        transform: scale(1);
        &:after{
            content:url("data:image/svg+xml,%3Csvg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.401 1.0554L4.31795 10.9347L0.333496 6.95029L1.51201 5.77178L4.19422 8.45399L11.1111 0L12.401 1.0554Z' fill='white'/%3E%3C/svg%3E%0A");
            display:block;
            opacity: 1;
        }
    }
}
// Hover
.input ~ .container:hover{
    .content{
        box-shadow: 0px 0px 15px 6px rgb(126 137 141 / 25%);
    }
}

// Checked & Hover
.input.input:checked ~ .container:hover{
    box-shadow: inset 0px 0px 0px 2.5px var(--gray-darkest);
    .toggle{
    }
}

// Unchecked & Hover
.input.input:not(:checked) ~ .container:hover{
    .toggle{
        opacity:1;
        transform: scale(1);
        background:none;
        box-shadow: inset 0px 0px 0px 2px var(--gray-light);
        transition: transform 0ms, opacity 50ms;
    }
}

// Focus
.input:focus ~ .container{
    .content{
        box-shadow: 0px 0px 15px 6px rgb(126 137 141 / 25%);
    }
    .toggle{
        opacity:1;
        transform: scale(1);
        background:none;
        box-shadow: inset 0px 0px 0px 2px var(--gray-light);
        // transition: transform 0ms, opacity 50ms;
    }
}

// Checked & Focussed
.input:focus.input:checked ~ .container{
    box-shadow: inset 0px 0px 0px 2px var(--gray-darkest);
    .content{
        box-shadow: 0px 0px 15px 6px rgb(126 137 141 / 25%);
    }
    .toggle{
        opacity: 1;
        background-color:var(--gray-darkest);
        transform: scale(1);
        box-shadow: none;
        &:after{
            content:url("data:image/svg+xml,%3Csvg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.401 1.0554L4.31795 10.9347L0.333496 6.95029L1.51201 5.77178L4.19422 8.45399L11.1111 0L12.401 1.0554Z' fill='white'/%3E%3C/svg%3E%0A");
            display:block;
            opacity: 1;
            
        }
        &:before{
            opacity:1;
            transform: scale(1);
        }
    }
}

// Unchecked & Focus

.input:focus.input:not(:checked) ~ .container{
    box-shadow: inset 0px 0px 0px 1px var(--gray);
    .content{
        box-shadow: 0px 0px 15px 6px rgb(126 137 141 / 25%);
    }
}

// Checked & Disabled
input:disabled.input ~ .container{
    background-color: var(--gray-light);
    box-shadow: none;
    .content{
    }
    .img{
        opacity: 0.5;
    }
    .toggle{
        background-color: var(--gray-dark);
    }
    .title{
        color:var(--gray-dark)
    }
}


// Disabled & hover
input:disabled ~ .container:hover{
    box-shadow: none!important;
    cursor: not-allowed;
    .content{
        box-shadow: 0px 0px 5px 2px rgb(126 137 141 / 15%);
    }
}
// Disabled & Focus
.input:focus ~ .container{
    box-shadow: inset 0px 0px 0px 1px var(--gray-dark);
}